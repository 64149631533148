<template>
    <div class="page home">

        <div class="page-background">
            <div id="bg-center"></div>
            <div id="bg-left"></div>
            <div id="bg-right"></div>
        </div>

        <div class="page-content">

            <transition name="fade">
                <div v-if="show">
                    <div class="page-foreground">
                        <div id="fg-center"></div>
                        <div id="fg-left"></div>
                        <div id="fg-right"></div>
                    </div>
                </div>
            </transition>

            <transition name="bounce">
                <header class="header" v-if="show">
                    <h1 class="header-logo" :class="logoClass"><span class="sr-only">Suivez le rhythme</span></h1>
                </header>
            </transition>

            <transition name="bounce">
                <div class="box box-content" v-if="show">
                    <h2 class="fw-reg">{{  title }}</h2>
                    <div class="box-buttons">
                        <router-link :to="toDiscover" class="btn-purple">{{ copydeck["BTN_DISCOVER"][lang]}}</router-link>
                        <router-link :to="toSetup" class="btn-red">{{ copydeck["BTN_PLAY"][lang]}}</router-link>
<!--                        <router-link :to="toPlay" class="btn-red">{{ copydeck["BTN_PLAY"][lang]}} 2</router-link>-->
                    </div>
                </div>
            </transition>

            <transition name="bounce">
                <p class="bottom-buttons" v-if="show">
                    <router-link :to="toCredits" class="btn-secondary">{{ copydeck["BTN_CREDITS"][lang]}}</router-link>
                    <a href="#" v-if="displayLang" @click="toggleLang" class="btn-secondary">{{ copydeck["BTN_LANG"][lang]}}</a>
                </p>
            </transition>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Home",
        data() {
            return {
                show: false
            }
        },

        computed:
            {
                displayLang ()
                {
                    return this.$store.state.displayLang || this.$store.state.displayLang === null;
                },

                title ()
                {
                    return this.$store.state.history.data.title[this.lang];
                },

                toDiscover ()
                {
                    return this.lang === "fr" ? "/fr/decouvrir" : "/en/discover";
                },

                toSetup ()
                {
                    return this.lang === "fr" ? "/fr/preparer" : "/en/setup";

                },

                toPlay ()
                {
                    return this.lang === "fr" ? "/fr/jouer/3/1/0" : "/en/play/3/1/0";

                },

                toCredits ()
                {
                    return this.lang === "fr" ? "/fr/credits" : "/en/credits";
                },

                logoClass ()
                {
                    return "header-logo-" + this.lang;
                }


            },

        methods:
            {

                toggleLang ()
                {
                    this.$store.dispatch("toggleLang")
                }
            },

        async mounted()
        {
            this.setLang();
            this.$nextTick(function () {
                // wait 0.3s then pop things out
                setTimeout(() => {
                    this.show = true;
                }, 300);
            }.bind(this));


        }
    }
</script>
