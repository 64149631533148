/**
 * Project - Partitions codées
 * (c) Les Affranchis - 2009-2022
 *
 * Author - Martin Tremblay - martin@lesaffranchis.ca
 * Author - Suzie Gordon
 */


import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import App from "./App.vue"
import store from "./store.js";
import routes from "./routes.js";
import UtilsMixins from "./mixins.js";


function onReady ()
{
     const components =  { App };
      //Vue.config.devtools = true;

     Vue.use(Vuex);
     Vue.use(VueRouter);


     let vuexStore = new Vuex.Store(store);

     let router = new VueRouter(routes);

    //make mixins global to the app
    Vue.mixin(UtilsMixins);

    new Vue (
        {
            store: vuexStore,

            router,
            components
        }
    ).$mount("#app");


}


console.log("Compilation date: " + __COMPILATION_DATE__)
document.addEventListener('DOMContentLoaded', onReady);

