<template>
	<canvas
        v-if="ready"
        width="1920"
        :height="height"
        :data-id="id"
        :data-type="mode"
        :id="selector"
        ref="canvas"
    >

    </canvas>

</template>

<script>

    import {mapState} from "vuex";

    export default
    {
        name: "Partition",
        props: ["mode", "id", "selector"],
		data()
        {
            return {
                height: 700,
                ready:false
			}
		},

        computed:
            {
                ...mapState(["nbTeams", "difficulty"]),

                // id ()
                // {
                //     return this.mode;
                // },


                gameIsReady ()
                {
                    return window.game !== undefined;
                },

                stopMode ()
                {
                    return this.mode === "game" ? parseInt(this.$route.params.stopMode) : this.$store.state.stopMode;
                }
            },

        methods:
            {
                onGameIsReady ()
                {
                    window.game.onCanvasReady(parseInt(this.nbTeams), parseInt(this.difficulty), parseInt(this.stopMode), this.mode, this.lang);
                    window.history_id = this.id;
                    window.game.resetSong();
                }
            },

        watch:
            {
                gameIsReady (newVal, oldVal)
                {
                    window.history_id = this.id;
                    window.game.resetSong();
                },

                nbTeams (newVal, oldVal)
                {
                    window.game.changeNumberOfTeams(parseInt(newVal));
                },

                difficulty (newVal, oldVal)
                {
                    window.game.changeDifficulty(parseInt(newVal));
                }
            },

        mounted ()
        {
            if (this.gameIsReady)
            {
                setTimeout(() => this.onGameIsReady(), 1);
            }
            else
            {
                window.addEventListener("game-is-ready", this.onGameIsReady.bind(this));
            }

            this.ready = true;
        },

        destroyed ()
        {
            window.game.removeApp();
        }
    }
</script>

<style scoped>
	canvas {
		margin: 1em auto;
		width: 100%;
		max-height: 60vh;
		transition: transform 0.2s;
        box-shadow: -0.125em 0.125em 0 0 rgb(0 0 0 / 20%);
		border-radius: 1em;
	}
</style>
