var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "header-title" },
      [_c("h1", [_vm._t("title")], 2), _vm._v(" "), _vm._t("content")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "header-buttons" },
      [
        _vm._t("buttons", [
          _vm.hasExtras
            ? _c(
                "a",
                {
                  staticClass: "btn-red btn-extras",
                  attrs: { href: _vm.pdfLink, target: "_blank" }
                },
                [_vm._v("Extras")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn-help", on: { click: _vm.showHelp } },
            [_c("span", { staticClass: "sr-only" }, [_vm._v("Aide")])]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "btn-home", attrs: { to: _vm.toHome } },
            [_c("span", { staticClass: "sr-only" }, [_vm._v("Accueil")])]
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }