<template>

	<div class="page play" v-if="ready">

		<div class="page-background">
			<div id="bg-center"></div>
			<div id="bg-left"></div>
			<div id="bg-right"></div>
		</div>

		<div class="page-content">
			<transition name="fade">
				<div v-if="show">
					<div class="page-foreground">
						<div id="fg-center"></div>
						<div id="fg-left"></div>
						<div id="fg-right"></div>
					</div>
				</div>
			</transition>

			<page-header :history="history">
				<template v-slot:title>
					La Symphonie « Du Nouveau Monde »
				</template>
			</page-header>

            <settings
                :disabled="true"/>

			<partition
                v-if="show"
                key="play"
                mode="game"
                :id="id"
                selector="gamePreview"
                height="700"
                class="is-start">

            </partition>



		</div>
	</div>
</template>

<script>
    import PageHeader from "../commons/PageHeader.vue";
    import Settings   from "../commons/Settings.vue";
    import Partition  from "../commons/Partition.vue";
    import {mapState} from "vuex";

    export default {
        name: "Play",
        components: {PageHeader, Partition, Settings},
        data() {
            return {
                show: false,
                ready:false
            }
        },

        computed:
            {
                ...mapState(["histories"]),

                id ()
                {
                    return this.$route.params.id;
                },


                history ()
                {
                    let id = this.$route.params.id;
                    let history = this.histories.find ( h => h.id == id);

                    return history;
                },
            },

        methods:
            {
                onMounted ()
                {
                    this.$store.commit("theme", this.history.data.image_folder);
                    this.show = true;
                    this.ready = true;



                }
            },

        async mounted()
        {
            this.setLang();

            let nbTeams = parseInt(this.$route.params.nbTeams);
            let difficulty = parseInt(this.$route.params.difficulty);
            let stopMode = parseInt(this.$route.params.stopMode);

            await this.$store.dispatch("settings", {nbTeams, difficulty, stopMode});

            setTimeout(() => this.onMounted(), 300);

            // this.$nextTick(function () {
            //     // wait 0.3s then pop things out
            //     setTimeout(() => this.onMounted(), 300);
            // }.bind(this));
        }
    }
</script>
