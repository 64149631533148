var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("canvas", {
        ref: "canvas",
        attrs: {
          width: "1920",
          height: _vm.height,
          "data-id": _vm.id,
          "data-type": _vm.mode,
          id: _vm.selector
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }