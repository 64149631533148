var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page home" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.show
            ? _c("div", [
                _c("div", { staticClass: "page-foreground" }, [
                  _c("div", { attrs: { id: "fg-center" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-left" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-right" } })
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "bounce" } }, [
          _vm.show
            ? _c("header", { staticClass: "header" }, [
                _c("h1", { staticClass: "header-logo", class: _vm.logoClass }, [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v("Suivez le rhythme")
                  ])
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "bounce" } }, [
          _vm.show
            ? _c("div", { staticClass: "box box-content" }, [
                _c("h2", { staticClass: "fw-reg" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box-buttons" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn-purple",
                        attrs: { to: _vm.toDiscover }
                      },
                      [_vm._v(_vm._s(_vm.copydeck["BTN_DISCOVER"][_vm.lang]))]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      { staticClass: "btn-red", attrs: { to: _vm.toSetup } },
                      [_vm._v(_vm._s(_vm.copydeck["BTN_PLAY"][_vm.lang]))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "bounce" } }, [
          _vm.show
            ? _c(
                "p",
                { staticClass: "bottom-buttons" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn-secondary",
                      attrs: { to: _vm.toCredits }
                    },
                    [_vm._v(_vm._s(_vm.copydeck["BTN_CREDITS"][_vm.lang]))]
                  ),
                  _vm._v(" "),
                  _vm.displayLang
                    ? _c(
                        "a",
                        {
                          staticClass: "btn-secondary",
                          attrs: { href: "#" },
                          on: { click: _vm.toggleLang }
                        },
                        [_vm._v(_vm._s(_vm.copydeck["BTN_LANG"][_vm.lang]))]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-background" }, [
      _c("div", { attrs: { id: "bg-center" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-left" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-right" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }