var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page interlude" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.show
            ? _c("div", [
                _c("div", { staticClass: "page-foreground" }, [
                  _c("div", { attrs: { id: "fg-center" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-left" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-right" } })
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "page-header",
          [
            [_vm._v("\n\t\t\t\t\tLa Symphonie « Du Nouveau Monde »\n\t\t\t\t")],
            _vm._v(" "),
            [_c("settings")]
          ],
          2
        ),
        _vm._v(" "),
        _vm.reachLast
          ? _c("div", { staticClass: "message" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.copydeck["MESSAGE_BRAVO_COMPLETE"][_vm.lang]))
              ]),
              _c("br"),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.copydeck["TITLE_MUSIC_USE_IN_GAME"][_vm.lang])
                )
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", { staticClass: "spotify" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.history.data.links.song.spotify[_vm.lang],
                        target: "_blank"
                      }
                    },
                    [_vm._v("Spotify")]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "apple_music" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.history.data.links.song.apple_music[_vm.lang],
                        target: "_blank"
                      }
                    },
                    [_vm._v("Apple Music")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.copydeck["TITLE_MUSIC_ALBUM_USE_IN_GAME"][_vm.lang]
                    )
                )
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", { staticClass: "spotify" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.history.data.links.album.spotify[_vm.lang],
                        target: "_blank"
                      }
                    },
                    [_vm._v("Spotify")]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "apple_music" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          _vm.history.data.links.album.apple_music[_vm.lang],
                        target: "_blank"
                      }
                    },
                    [_vm._v("Apple Music")]
                  )
                ])
              ])
            ])
          : _c("div", { staticClass: "message" }, [
              _vm._v(
                _vm._s(_vm.copydeck["MESSAGE_BRAVO_LETS_CONTINUE"][_vm.lang])
              )
            ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons text-big" },
          [
            _c(
              "router-link",
              { staticClass: "btn-red icon-prev", attrs: { to: _vm.toStart } },
              [_vm._v(_vm._s(_vm.copydeck["BTN_RESTART_FROM_START"][_vm.lang]))]
            ),
            _vm._v(" "),
            !_vm.isComplete
              ? _c(
                  "router-link",
                  {
                    staticClass: "btn-red icon-prev",
                    attrs: { to: _vm.toLastSection }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.copydeck["BTN_RESTART_LAST_SECTION"][_vm.lang])
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn-purple icon-next",
                class: _vm.reachLastClass,
                attrs: { to: _vm.toNextSection }
              },
              [_vm._v(_vm._s(_vm.copydeck["BTN_START_NEXT_SECTION"][_vm.lang]))]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-background" }, [
      _c("div", { attrs: { id: "bg-center" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-left" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-right" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }