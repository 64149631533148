<template>
    <div>
        <article v-for="(faq,index) in data" :key="'faq' + index">
<!--        <p><strong>{{ faq.data.title[lang]}}</strong></p>-->
        <p v-html="faq.data.text[lang]"></p>
        </article>

    </div>
</template>

<script>

    import {mapState} from "vuex";

    export default
    {
        name: "Help",

        props:["data"],
        computed:
            {
                ...mapState(["faqs"]),
                
                datas ()
                {
                    if (this.data)
                    {
                        return this.data;
                    }
                    
                    return this.faqs;
                }
            }
    }
</script>

<style scoped>
    div {
        text-align: left;
        color: #192d36;
    }
</style>