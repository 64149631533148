<template>
	<div>
		<div v-if="!disabled">
			<div class="settings">
                <div class="settings">
                    <span v-html='copydeck["LABEL_NUMBER_OF_TEAMS"][lang]'></span>
                    <input type="radio" id="teams-1" name="nb-teams" value="1" v-bind:checked="$store.state.nbTeams == 1" @click="$store.state.nbTeams = 1" /><label for="teams-1" class="sr-only">1</label>
                    <input type="radio" id="teams-2" name="nb-teams" value="2" v-bind:checked="$store.state.nbTeams == 2" @click="$store.state.nbTeams = 2" /><label for="teams-2" class="sr-only">2</label>
                    <input type="radio" id="teams-3" name="nb-teams" value="3" v-bind:checked="$store.state.nbTeams == 3" @click="$store.state.nbTeams = 3" /><label for="teams-3" class="sr-only">3</label>
                </div>
                <div class="settings">
                    <span v-html='copydeck["LABEL_LEVEL_OF_DIFFICULTY"][lang]'></span>
                    <input type="radio" id="difficulty-easy" name="difficulty" value="1" v-bind:checked="$store.state.difficulty == 1" @click="$store.state.difficulty = 1" /><label for="difficulty-easy" class="sr-only">Facile</label>
                    <input type="radio" id="difficulty-medium" name="difficulty" value="2" v-bind:checked="$store.state.difficulty == 2" @click="$store.state.difficulty = 2" /><label for="difficulty-medium" class="sr-only">Moyen</label>
                    <input type="radio" id="difficulty-hard" name="difficulty" value="3" v-bind:checked="$store.state.difficulty == 3" @click="$store.state.difficulty = 3" /><label for="difficulty-hard" class="sr-only">Difficile</label>
                </div>
                <div class="settings">
                    <span v-html='copydeck["LABEL_MODE_OF_PLAY"][lang]'></span>
                    <input type="radio" id="mode-uninterrupted" name="mode" value="0" v-bind:checked="$store.state.stopMode == 0" @click="$store.state.stopMode = 0" />
                    <label for="mode-uninterrupted" class=""><span>{{copydeck["LABEL_MODE_OF_PLAY_CONTINUE"][lang]}}</span></label>
                    <input type="radio" id="mode-interlude" name="mode" value="1" v-bind:checked="$store.state.stopMode == 1" @click="$store.state.stopMode = 1" />
                    <label for="mode-interlude" class=""><span v-html="copydeck['LABEL_MODE_OF_PLAY_WITH_PAUSE'][lang]"></span></label>
                </div>

			</div>
		</div>
		<div v-if="disabled">
			<div class="settings">
                <div class="settings">
                    <span v-html=' copydeck["LABEL_NUMBER_OF_TEAMS"][lang]'></span>
                    <input type="radio" id="nb-teams" name="nb-teams" v-bind:value="$store.state.nbTeams" checked />
                    <label for="nb-teams" class="sr-only">{{$store.state.nbTeams}}</label>
                </div>
                <div class="settings">
                    <span v-html='copydeck["LABEL_LEVEL_OF_DIFFICULTY"][lang]'></span>
                    <input type="radio" id="difficulty" name="difficulty" v-bind:value="$store.state.difficulty" checked />
                    <!-- @todo: the label should change depending on the value //-->
                    <label for="difficulty" class="sr-only">Facile</label>
                </div>
                <div class="settings">
                    <span v-html=' copydeck["LABEL_MODE_OF_PLAY"][lang]'></span>
                    <input type="radio" id="mode-interlude" name="mode" v-bind:value="$store.state.stopMode" checked />
                    <!-- @todo: the label should change depending on the value //-->
                    <label for="mode-interlude" class="sr-only">Avec pauses</label>
                </div>
			</div>
		</div>
	</div>
</template>

<script>

    export default {
        name: "Settings",
        props: {
            disabled: {
                type: Boolean,
                default: true
            },
		},


        mounted ()
        {
            this.setLang();

        }
    }

</script>