<template>
    <div>
        <img :src="data.img" :alt="data.name" />
        <p class="description" v-html="data.details">

        </p>
    </div>
</template>

<script>
    export default
    {
        name: "Info",
        props:["data"]
    }
</script>

<style scoped>
    img
    {
        max-height:25vh;
    }
</style>