var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "quick-fade" } }, [
    _vm.active
      ? _c("div", [
          _c("div", { staticClass: "modal-overlay", on: { click: _vm.close } }),
          _vm._v(" "),
          _c("div", { staticClass: "modal", class: _vm.variant }, [
            _c(
              "button",
              { staticClass: "btn-close", on: { click: _vm.close } },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Fermer")])]
            ),
            _vm._v(" "),
            _c("h2", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-content scrollable" },
              [
                _c(_vm.component, {
                  tag: "component",
                  attrs: { data: _vm.data }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }