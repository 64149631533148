<template>
	<div class="box">
		<div class="box-header">
			<strong>{{ copydeck["LABEL_TEAM"][lang]}} {{index}}</strong>
			<img :src="imageTeam" :alt="imageTeamAlt" />
		</div>
		<div class="box-content">
			<button class="btn-prev" v-on:click="$refs.carousel.goToPrev()" v-bind:disabled="currentSlide === 0">
				<span class="sr-only">Précédent</span>
			</button>

			<vue-agile ref="carousel" @after-change="setCurrentSlide($event)" class="box-slides" :dots="false" :nav-buttons="false">
				<div class="box-slide" v-for="(slide, index) in slides" :key="index"><img v-bind:src="slide.img" alt="" width="600" /></div>
			</vue-agile>

			<p class="description"><strong>{{ slides[currentSlide]['name'] }}</strong> - {{ slides[currentSlide]['action'] }}</p>

			<button class="btn-help" v-on:click="showInfo">
                <span class="sr-only">Détails</span>
            </button>

			<button class="btn-next" v-on:click="$refs.carousel.goToNext()" v-bind:disabled="currentSlide === slides.length - 1">
				<span class="sr-only">Suivant</span>
			</button>
		</div>
	</div>
</template>

<script>
    import Hub from '../events/Hub';
    import Info from "../page/Info.vue";
    import { VueAgile } from 'vue-agile';
    import { mapState} from "vuex";

    export default {
        name: "Team",
        props: ['index', "group"],
        components: {VueAgile},

        data()
        {
            return {
                data: {},
                currentSlide: 0

            }
        },

        computed:
            {
                ...mapState(["instruments"]),

                slides ()
                {
                    return this.instruments
                                .filter( instrument => instrument.data.group === this.group)
                                .sort( (a,b) => a.position - b.position)
                                .map( instrument => (
                                    {
                                        name: instrument.data.name[this.lang],
                                        action: instrument.data.action[this.lang],
                                        img: "https://lesaffranchis.s3.amazonaws.com/partitions-codees/instruments/" + instrument.data.image,
                                        details: instrument.data.description[this.lang]
                                    }))
                },


                details ()
                {
                    let slides = this.slides;

                    return slides[this.currentSlide];
                },


                imageTeam ()
                {
                    let img = ["carre.svg" ,"triangle.svg", "cercle.svg"];

                    return "/_/images/" + img[this.index - 1];
                },

                imageTeamAlt()
                {
                    let alt = ["Carre", "Triangle", "Cercle"];

                    return alt[this.index - 1];
                }
            },


        methods:
        {



			setCurrentSlide (event)
            {
				this.currentSlide = event.currentSlide;
			},

            showInfo: function ()
            {
                Hub.$emit('open-modal');

                // @todo: make dynamic.
                Hub.$emit('set-modal-data', this.details, this.details.name, Info);
            }
        },
    }
</script>