<template>

    <div class="page menu">

        <div class="page-background">
            <div id="bg-center"></div>
            <div id="bg-left"></div>
            <div id="bg-right"></div>
        </div>

        <div class="page-content carousel">

            <transition name="fade">
                <div v-if="show">
                    <div class="page-foreground">
                        <div id="fg-center"></div>
                        <div id="fg-left"></div>
                        <div id="fg-right"></div>
                    </div>
                </div>
            </transition>

            <transition name="bounce">
                <header class="header" v-if="show">
                    <h1 class="header-logo" :class="logoClass"><span class="sr-only">Suivez le rhythme</span></h1>
                </header>
            </transition>

            <transition name="bounce">
                <div class="mask">
                    <vue-agile
                            ref="carousel"
                            @after-change="setCurrentSlide($event)"
                            class="slides"
                            :initial-slide="initialSlide"
                            :dots="false"
                            :infinite="false"
                            :nav-buttons="false">

                        <div class="slide" v-for="(slide, index) in currentHistories" :key="index">

                            <img v-bind:src="slide.img" alt="" width="600" />

                        </div>
                    </vue-agile>
                </div>
            </transition>

            <transition name="bounce">
                <h2 class="slide-title" v-html="currentHistories[currentSlide]['caption']"></h2>
            </transition>

            <transition name="fade">
                <button class="btn-prev" v-on:click="$refs.carousel.goToPrev()" v-bind:disabled="currentSlide === 0">
                    <span class="sr-only">Précédent</span>
                </button>
            </transition>

            <transition name="fade">
                <button class="btn-next" v-on:click="$refs.carousel.goToNext()" v-bind:disabled="currentSlide === histories.length - 1">
                    <span class="sr-only">Suivant</span>
                </button>
            </transition>

            <transition name="bounce">
                <div class="box-buttons" v-if="show">
                    <router-link :to="toDiscover" class="btn-purple">{{ copydeck["BTN_DISCOVER"][lang]}}</router-link>
                    <router-link :to="toSetup" class="btn-red">{{ copydeck["BTN_PLAY"][lang]}}</router-link>
                </div>
            </transition>

            <transition name="bounce">
                <div class="dots">
                    <button
                            v-for="i in currentHistories.length"
                            :key="i"
                            class="dot"
                            v-bind:class="{ viewed : currentSlide >= i - 1 }"
                            v-on:click="$refs.carousel.goTo(i - 1)"
                    >
                        <span class="sr-only">{{ i }}</span>
                    </button>
                </div>
            </transition>

            <transition name="bounce">
                <p class="bottom-buttons" v-if="show">
                    <router-link :to="toCredits" class="btn-secondary">{{ copydeck["BTN_CREDITS"][lang]}}</router-link>
                </p>
            </transition>

        </div>

    </div>
</template>

<script>
    import PageHeader from "../commons/PageHeader.vue";
    import { VueAgile } from 'vue-agile';
    import { mapState } from "vuex";

    export default
    {
        name: "Menu",
        components: {PageHeader, VueAgile},
        data () {
            return {
                show: false,
                currentSlide: 0
            }
        },

        computed:
                {
                    ...mapState(["theme", "histories"]),

                    currentHistories ()
                    {
                        return this.histories.map (
                                history =>
                                        (
                                                {
                                                    caption: history.data.title[this.lang],
                                                    img: `https://lesaffranchis.s3.amazonaws.com/partitions-codees/${history.data.image_folder}/${history.data.image_menu[this.lang]}`
                                                }))
                    },

                    initialSlide ()
                    {
                        let id = this.$route.params.id;

                        if (id !== undefined)
                        {
                            let index = this.histories.findIndex( h => parseInt(h.id) === parseInt(id));

                            return index >= 0 && index < this.histories.length ? index : 0;
                        }

                        return 0;
                    },


                    displayLang ()
                    {
                        return this.$store.state.displayLang || this.$store.state.displayLang === null;
                    },

                    toDiscover ()
                    {
                        // this.$store.commit("currentHistory", this.currentSlide);

                        let history = this.histories[this.currentSlide];

                        let id = history.id;
                        let historySlug = history.data.slug[this.lang];
                        let slug = {fr: "decouvrir", en: "discover"}[this.lang];

                        let path = `/${this.lang}/${id}/${historySlug}/${slug}`;

                        return path;
                    },

                    toSetup ()
                    {
                        let history = this.histories[this.currentSlide];

                        let id = history.id;
                        let historySlug = history.data.slug[this.lang];
                        let slug = {fr: "preparer", en: "setup"}[this.lang];

                        let path = `/${this.lang}/${id}/${historySlug}/${slug}`;

                        return path;
                    },

                    toCredits ()
                    {
                        let history = this.histories[this.currentSlide];

                        let id = history.id;
                        let historySlug = history.data.slug[this.lang];
                        let slug = {fr: "credits", en: "credits"}[this.lang];

                        let path = `/${this.lang}/${id}/${historySlug}/${slug}`;

                        return path;

                        //return this.lang === "fr" ? "/fr/credits" : "/en/credits";
                    },

                    logoClass ()
                    {
                        return "header-logo-" + this.lang;
                    },

                    currentHistory ()
                    {
                        let id = this.$route.params.id;

                        if (id !== null)
                        {
                            let history = this.histories.find(h => h.id == id);

                            return history;
                        }
                        else
                        {
                            return this.histories[0];
                        }
                    }
                },

        methods: {
            setCurrentSlide (event) {
                this.currentSlide = event.currentSlide;

                let current = this.histories[this.currentSlide];
                console.log(current.data.image_folder);
                this.$store.commit("theme", current.data.image_folder);
            },

            onMounted ()
            {
                this.show = true;
            }

        },

        mounted()
        {
            setTimeout(() =>
            {
                this.onMounted();
            }, 500);
        }
    }
</script>
