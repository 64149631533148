var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        { class: "theme-" + _vm.theme, attrs: { id: "mainContainer" } },
        [
          _c(
            "transition",
            { attrs: { name: _vm.transitionName, mode: _vm.transitionMode } },
            [_c("router-view")],
            1
          ),
          _vm._v(" "),
          _c("modal"),
          _vm._v(" "),
          _c("div", { staticClass: "rotate-screen" }, [
            _vm._v(_vm._s(_vm.copydeck["TURN_YOUR_SCREEN"][_vm.lang]))
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }