var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "box-header" }, [
      _c("strong", [
        _vm._v(
          _vm._s(_vm.copydeck["LABEL_TEAM"][_vm.lang]) + " " + _vm._s(_vm.index)
        )
      ]),
      _vm._v(" "),
      _c("img", { attrs: { src: _vm.imageTeam, alt: _vm.imageTeamAlt } })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box-content" },
      [
        _c(
          "button",
          {
            staticClass: "btn-prev",
            attrs: { disabled: _vm.currentSlide === 0 },
            on: {
              click: function($event) {
                _vm.$refs.carousel.goToPrev()
              }
            }
          },
          [_c("span", { staticClass: "sr-only" }, [_vm._v("Précédent")])]
        ),
        _vm._v(" "),
        _c(
          "vue-agile",
          {
            ref: "carousel",
            staticClass: "box-slides",
            attrs: { dots: false, "nav-buttons": false },
            on: {
              "after-change": function($event) {
                _vm.setCurrentSlide($event)
              }
            }
          },
          _vm._l(_vm.slides, function(slide, index) {
            return _c("div", { key: index, staticClass: "box-slide" }, [
              _c("img", { attrs: { src: slide.img, alt: "", width: "600" } })
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("p", { staticClass: "description" }, [
          _c("strong", [_vm._v(_vm._s(_vm.slides[_vm.currentSlide]["name"]))]),
          _vm._v(" - " + _vm._s(_vm.slides[_vm.currentSlide]["action"]))
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "btn-help", on: { click: _vm.showInfo } }, [
          _c("span", { staticClass: "sr-only" }, [_vm._v("Détails")])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn-next",
            attrs: { disabled: _vm.currentSlide === _vm.slides.length - 1 },
            on: {
              click: function($event) {
                _vm.$refs.carousel.goToNext()
              }
            }
          },
          [_c("span", { staticClass: "sr-only" }, [_vm._v("Suivant")])]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }