var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.data, function(faq, index) {
      return _c("article", { key: "faq" + index }, [
        _c("p", { domProps: { innerHTML: _vm._s(faq.data.text[_vm.lang]) } })
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }