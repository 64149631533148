<template>

	<div class="page interlude">

		<div class="page-background">
			<div id="bg-center"></div>
			<div id="bg-left"></div>
			<div id="bg-right"></div>
		</div>

		<div class="page-content">

			<transition name="fade">
				<div v-if="show">
					<div class="page-foreground">
						<div id="fg-center"></div>
						<div id="fg-left"></div>
						<div id="fg-right"></div>
					</div>
				</div>
			</transition>

			<page-header>
				<template v-slot:title>
					La Symphonie « Du Nouveau Monde »
				</template>
				<template v-slot:content>
					<settings></settings>
				</template>
			</page-header>


            <div class="message" v-if="reachLast">

                <span>{{ copydeck["MESSAGE_BRAVO_COMPLETE"][lang]}}</span><br>
                <span> {{ copydeck["TITLE_MUSIC_USE_IN_GAME"][lang]}}</span>
                <ul>
                    <li class="spotify"><a :href='history.data.links.song.spotify[lang]' target="_blank">Spotify</a></li>
                    <li class="apple_music"><a :href='history.data.links.song.apple_music[lang]' target="_blank">Apple Music</a></li>
                </ul>

                <span> {{ copydeck["TITLE_MUSIC_ALBUM_USE_IN_GAME"][lang]}}</span>
                <ul>
                    <li class="spotify"><a :href='history.data.links.album.spotify[lang]' target="_blank">Spotify</a></li>
                    <li class="apple_music"><a :href='history.data.links.album.apple_music[lang]' target="_blank">Apple Music</a></li>
                </ul>

            </div>

            <div class="message" v-else>{{ copydeck["MESSAGE_BRAVO_LETS_CONTINUE"][lang]}}</div>



			<div class="buttons text-big">
				<router-link :to="toStart" class="btn-red icon-prev">{{ copydeck["BTN_RESTART_FROM_START"][lang]}}</router-link>
				<router-link :to="toLastSection" v-if="!isComplete"  class="btn-red icon-prev">{{ copydeck["BTN_RESTART_LAST_SECTION"][lang]}}</router-link>
				<router-link :class="reachLastClass" :to="toNextSection"  class="btn-purple icon-next">{{ copydeck["BTN_START_NEXT_SECTION"][lang]}}</router-link>
			</div>
		</div>
	</div>
</template>

<script>
    import PageHeader from "../commons/PageHeader.vue";
    import Settings from "../commons/Settings.vue";
    import {mapState} from "vuex";


    export default {
        name: "Interlude",
        components: {PageHeader, Settings},
        data() {
            return {
                show: false
            }
        },


        computed:
            {
                ...mapState(["histories"]),


                id ()
                {
                    return this.$route.params.id;
                },


                history ()
                {
                    let id = this.$route.params.id;
                    let history = this.histories.find ( h => h.id == id);

                    return history;
                },
                
                sections ()
                {
                    return this.history.song.data.sections.length + 1;
                },

                reachLast ()
                {
                    return (this.stopMode > this.nbSections) || this.isComplete;

                },

                isComplete ()
                {
                    return this.$route.params.isComplete !== undefined;
                },

                reachLastClass ()
                {
                    if (!this.reachLast)
                        return "";

                    return "vHide";
                },


                nbSections ()
                {
                    return parseInt(this.sections);
                },

                nbTeams ()
                {
                    return this.$route.params.nbTeams;
                },

                difficulty ()
                {
                    return this.$route.params.difficulty;
                },

                stopMode ()
                {
                    return this.$route.params.stopMode;
                },

                settings ()
                {
                    return {nbTeams: this.nbTeams, difficulty: this.difficulty, stopMode: this.isComplete ? parseInt(this.$route.params.isComplete) : this.stopMode};
                },


                toStart ()
                {
                    let id = this.history.id;
                    let historySlug = this.history.data.slug[this.lang];
                    let slug = {fr: "preparer", en: "setup"}[this.lang];

                    let path = `/${this.lang}/${id}/${historySlug}/${slug}`;

                    return path;
                },

                toLastSection ()
                {
                    let id = this.history.id;
                    let historySlug = this.history.data.slug[this.lang];
                    let slug = {fr: "jouer", en: "play"}[this.lang];

                    let path = `/${this.lang}/${id}/${historySlug}/${slug}`;


                    return `${path}/${this.nbTeams}/${this.difficulty}/${this.stopMode - 1}`;
                },

                toNextSection ()
                {
                    let id = this.history.id;
                    let historySlug = this.history.data.slug[this.lang];
                    let slug = {fr: "jouer", en: "play"}[this.lang];

                    let path = `/${this.lang}/${id}/${historySlug}/${slug}`;

                    return `${path}/${this.nbTeams}/${this.difficulty}/${this.stopMode}`;
                }
            },

        async mounted()
        {
            this.setLang();
            this.$store.dispatch("settings", this.settings);

            this.$nextTick(function () {
                // wait 0.3s then pop things out
                setTimeout(() => {
                    this.show = true;
                    this.$store.commit("theme", this.history.data.image_folder);
                }, 300);
            }.bind(this));
        }
    }
</script>

<style scoped>
    .vHide { visibility: hidden; }

    .spotify:before
    {
        content: '';
        display: inline-block;
        height: 1em;
        width: 1em;
        background-image: url('https://lesaffranchis.s3.amazonaws.com/partitions-codees/logo/musique/Spotify_Icon_RGB_Green.png');
        background-size: 1em;
        filter: drop-shadow(0.1em 0.1em 3px black);
        margin-right: 0.5em;
    }

    .apple_music:before
    {
        content: '';
        display: inline-block;
        height: 1em;
        width: 1em;
        background-image: url('https://lesaffranchis.s3.amazonaws.com/partitions-codees/logo/musique/Apple_Music_Icon_RGB_lg_073120.svg');
        background-size: 1em;
        filter: drop-shadow(0.1em 0.1em 3px black);
        margin-right: 0.5em;
    }
</style>
