var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page credits" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.show
            ? _c("div", [
                _c("div", { staticClass: "page-foreground" }, [
                  _c("div", { attrs: { id: "fg-center" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-left" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-right" } })
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("page-header", { attrs: { history: _vm.history } }, [_vm._v(">")]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center color-green" }, [
          _c("h1", { staticClass: "page-title" }, [
            _vm._v(_vm._s(_vm.copydeck["PAGE_CREDITS"][_vm.lang]))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "scrollable text-big" },
            _vm._l(_vm.historyCredits, function(credit) {
              return _c("div", [
                !credit.data.isList
                  ? _c("div", [
                      credit.data.logo_title[_vm.lang] !== ""
                        ? _c("h3", [
                            _vm._v(_vm._s(credit.data.logo_title[_vm.lang]))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      credit.data.logo !== ""
                        ? _c("img", {
                            staticClass: "logo",
                            attrs: {
                              src:
                                "https://lesaffranchis.s3.amazonaws.com/" +
                                credit.data.logo
                            }
                          })
                        : _vm._e()
                    ])
                  : _c("div", {
                      domProps: {
                        innerHTML: _vm._s(credit.data.list[_vm.lang])
                      }
                    })
              ])
            }),
            0
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-background" }, [
      _c("div", { attrs: { id: "bg-center" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-left" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-right" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }