<template>

	<div class="page setup">

		<div class="page-background">
			<div id="bg-center"></div>
			<div id="bg-left"></div>
			<div id="bg-right"></div>
		</div>

		<div class="page-content">

			<transition name="fade">
				<div v-if="show">
					<div class="page-foreground">
						<div id="fg-center"></div>
						<div id="fg-left"></div>
						<div id="fg-right"></div>
					</div>
				</div>
			</transition>

			<page-header ref="page_header" :history="history">
				<template v-slot:title>
					{{ history.data.title[lang] }}
				</template>
			</page-header>

			<settings
                :disabled="false"></settings>

			<partition
                mode="preview"
                :id="id"
                selector="gamePreview"
                height="280"
                key="preview"
                class="preview">

            </partition>

			<div class="teams">
				<team
                    v-for="i in intNbTeams"
                    :key="'team' + i"
                    :group="teamInstruments(i)"
                    :index="i"></team>

			</div>

			<p class="text-center">
                <router-link
                    :to="routeToGame"
                    class="btn-red text-bigger"
                >{{ copydeck["BTN_CONTINUE"][lang]}}</router-link>
            </p>

            <p class="text-center setup-footer">
                {{ credits }}
            </p>

		</div>
	</div>
</template>

<script>
    import PageHeader from "../commons/PageHeader.vue";
    import Settings from "../commons/Settings.vue";
    import Partition from "../commons/Partition.vue";
    import Team from "../commons/Team.vue";

    import {mapState} from "vuex";

    export default {
        name: "Setup",
        components: {PageHeader, Partition, Team, Settings},
        data() {
            return {
                show: false
            }
        },

        computed:
            {
                ...mapState(["nbTeams", "difficulty", "stopMode", "shouldShowHelp", "histories"]),

                id ()
                {
                    return this.$route.params.id;
                },


                history ()
                {
                    let id = this.$route.params.id;
                    let history = this.histories.find ( h => h.id == id);

                    return history;
                },

                intNbTeams ()
                {
                    return parseInt(this.nbTeams);
                },


                routeToGame ()
                {

                    let id = this.history.id;
                    let historySlug = this.history.data.slug[this.lang];
                    let slug = {fr: "jouer", en: "play"}[this.lang];

                    let path = `/${this.lang}/${id}/${historySlug}/${slug}/`;


                    return path + this.nbTeams + "/" + this.difficulty + "/" + this.stopMode;
                },

                credits ()
                {
                    return this.history.data.credits[this.lang];
                }
            },


        methods:
            {
                teamInstruments (index)
                {
                    return this.history.data.instruments[`team${index}`]
                },


                changeTeamCount (teamCount)
                {

                },

                onMounted ()
                {
                    let id = this.id;
                    window.dispatchEvent(new CustomEvent("song-change", {detail: { id } }));
    
    
                    this.$store.commit("theme", this.history.data.image_folder);

                    this.show = true;

                    if (this.shouldShowHelp)
                    {
                        setTimeout(() => this.$refs.page_header.showHelp(), 1);

                        this.$store.commit("shouldShowHelp", false);
                    }
                }
            },


        async mounted()
        {
            this.setLang();

            setTimeout(() => {
                this.onMounted();
            }, 300);

            // this.$nextTick(function () {
            //     // wait 0.3s then pop things out
            //     setTimeout(() => {
            //         this.show = true;
            //     }, 300);
            // }.bind(this));


        }
    }
</script>
