<template>

    <div class="page credits">

        <div class="page-background">
            <div id="bg-center"></div>
            <div id="bg-left"></div>
            <div id="bg-right"></div>
        </div>

        <div class="page-content">

            <transition name="fade">
                <div v-if="show">
                    <div class="page-foreground">
                        <div id="fg-center"></div>
                        <div id="fg-left"></div>
                        <div id="fg-right"></div>
                    </div>
                </div>
            </transition>

            <page-header :history="history">></page-header>

            <div class="text-center color-green">
                <h1 class="page-title">{{ copydeck["PAGE_CREDITS"][lang]}}</h1>
                <div class="scrollable text-big">

                    <div v-for="credit in historyCredits">

                        <div v-if="!credit.data.isList">
                            <h3 v-if="credit.data.logo_title[lang] !== ''">{{credit.data.logo_title[lang]}}</h3>
                            <img
                                class="logo"
                                v-if="credit.data.logo !== ''"
                                :src="'https://lesaffranchis.s3.amazonaws.com/' + credit.data.logo"
                                 />
                        </div>

                        <div v-else v-html="credit.data.list[lang]"></div>
                    </div>


                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import PageHeader from "../commons/PageHeader.vue";
    import {mapState} from "vuex";
    export default
    {
        name: "Credits",
        components: {PageHeader},
        data() {
            return {
                show: false
            }
        },

        computed:
            {
                ...mapState(["credits", "histories"]),

                id ()
                {
                    return this.$route.params.id;
                },


                history ()
                {
                    let id = this.$route.params.id;
                    let history = this.histories.find ( h => h.id == id);

                    return history;
                },

                historyCredits ()
                {
                    return this.credits.filter ( c => parseInt(c.id_history) === parseInt(this.id));
                }
            },

        async mounted()
        {
            this.setLang();

            this.$nextTick(function () {
                // wait 0.3s then pop things out
                setTimeout(() => {
                    this.show = true;
                }, 300);
            }.bind(this));
        }
    }
</script>

<style scoped>
    .page-title { margin: -1em 0 1em; padding-right:2em;}
    .scrollable { max-height: 60vh; }

    .logo
    {
        max-width: 20vw;
        padding-bottom:50px;
    }
</style>
