<template>
    <div id="mainContainer" v-if="ready" :class="`theme-${theme}`"><!-- this node will be removed on mount -->

        <transition :name="transitionName" :mode="transitionMode">

            <router-view></router-view>
        </transition>

        <modal></modal>
        <div class="rotate-screen">{{ copydeck["TURN_YOUR_SCREEN"][lang]}}</div>
    </div>
</template>

<script>

import Vue from "vue";
import _ from 'lodash';
import {mapState} from "vuex";
import Modal from "./commons/Modal.vue";

Object.defineProperty(Vue.prototype, '$_', {value: _}); //add lodash globally

export default {
    name: "App",
    components: {Modal},

    data()
    {
        return {
            transitionName: 'slide-left',
            transitionMode: 'out-in',
            ready: false
        }
    },

    computed:
        {
            ...mapState(["nbTeams", "difficulty", "stopMode", "theme", "histories"]),

            id ()
            {
                return this.$route.params.id;
            },


            history ()
            {
                let id = this.$route.params.id;
                let history = this.histories.find ( h => h.id == id);
                return history;
            },
        },

    methods:
        {

            onSectionComplete (event)
            {
                let id = this.history.id;
                let historySlug = this.history.data.slug[this.lang];
                let slug = {fr: "interlude", en: "interlude"}[this.lang];

                let path = `/${this.lang}/${id}/${historySlug}/${slug}`;

                this.$router.push(`${path}/${this.nbTeams}/${this.difficulty}/${event.section}`);
            },

            onGameComplete (event)
            {
                let id = this.history.id;
                let historySlug = this.history.data.slug[this.lang];
                let slug = {fr: "interlude", en: "interlude"}[this.lang];

                let path = `/${this.lang}/${id}/${historySlug}/${slug}`;

                this.$router.push(`${path}/${this.nbTeams}/${this.difficulty}/${event.section}/${this.stopMode}`);
            },

            getMp3 ()
            {
                return this.history.data.audio_file;
            },

        },


    watch:
        {
            '$route' (to, from) {
                if(from.name == 'interlude') {
                    this.transitionName = 'fade'
                } else if(to.name == 'play' && from.name == 'setup') {
                    this.transitionName = 'fade'
                } else if( to.name == 'discover' ) {
                    this.transitionName = 'slide-right'
                    this.transitionMode = ''
                } else if( from.name == 'discover' && to.name == 'home' ) {
                    this.transitionName = 'slide-left'
                    this.transitionMode = ''
                } else {
                    this.transitionName = 'slide-left'
                }

                this.setLang();
            }
        },

    async mounted()
    {
        await this.$store.dispatch("all");
        await this.$store.dispatch("init");

        window.app = this;

            this.setLang();
        window.addEventListener("section-complete", this.onSectionComplete.bind(this))
        window.addEventListener("game-complete", this.onGameComplete.bind(this))

        this.ready = true;

        let id = parseInt(this.$route.params.id);

        setTimeout(() => window.dispatchEvent(new CustomEvent("app-is-ready", {detail: { id } })), 10);
    }


}
</script>
