var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page setup" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.show
            ? _c("div", [
                _c("div", { staticClass: "page-foreground" }, [
                  _c("div", { attrs: { id: "fg-center" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-left" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-right" } })
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "page-header",
          { ref: "page_header", attrs: { history: _vm.history } },
          [
            [
              _vm._v(
                "\n\t\t\t\t\t" +
                  _vm._s(_vm.history.data.title[_vm.lang]) +
                  "\n\t\t\t\t"
              )
            ]
          ],
          2
        ),
        _vm._v(" "),
        _c("settings", { attrs: { disabled: false } }),
        _vm._v(" "),
        _c("partition", {
          key: "preview",
          staticClass: "preview",
          attrs: {
            mode: "preview",
            id: _vm.id,
            selector: "gamePreview",
            height: "280"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teams" },
          _vm._l(_vm.intNbTeams, function(i) {
            return _c("team", {
              key: "team" + i,
              attrs: { group: _vm.teamInstruments(i), index: i }
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "text-center" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn-red text-bigger",
                attrs: { to: _vm.routeToGame }
              },
              [_vm._v(_vm._s(_vm.copydeck["BTN_CONTINUE"][_vm.lang]))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("p", { staticClass: "text-center setup-footer" }, [
          _vm._v("\n                " + _vm._s(_vm.credits) + "\n            ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-background" }, [
      _c("div", { attrs: { id: "bg-center" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-left" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-right" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }