var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page discover" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page-content carousel" },
      [
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.show
            ? _c("div", [
                _c("div", { staticClass: "page-foreground" }, [
                  _c("div", { attrs: { id: "fg-center" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-left" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-right" } })
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "page-header",
          { attrs: { history: _vm.history } },
          [
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.history.data.title[_vm.lang]) +
                  "\n            "
              )
            ]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mask" },
          [
            _c(
              "vue-agile",
              {
                ref: "carousel",
                staticClass: "slides",
                attrs: { dots: false, infinite: false, "nav-buttons": false },
                on: {
                  "after-change": function($event) {
                    _vm.setCurrentSlide($event)
                  }
                }
              },
              _vm._l(_vm.currentSlides, function(slide, index) {
                return _c("div", { key: index, staticClass: "slide" }, [
                  _c("img", {
                    attrs: { src: slide.img, alt: "", width: "600" }
                  })
                ])
              }),
              0
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn-prev",
            attrs: { disabled: _vm.currentSlide === 0 },
            on: {
              click: function($event) {
                _vm.$refs.carousel.goToPrev()
              }
            }
          },
          [_c("span", { staticClass: "sr-only" }, [_vm._v("Précédent")])]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn-next",
            attrs: { disabled: _vm.currentSlide === _vm.slides.length - 1 },
            on: {
              click: function($event) {
                _vm.$refs.carousel.goToNext()
              }
            }
          },
          [_c("span", { staticClass: "sr-only" }, [_vm._v("Suivant")])]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "box",
          domProps: {
            innerHTML: _vm._s(_vm.currentSlides[_vm.currentSlide]["caption"])
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dots" },
          _vm._l(_vm.currentSlides.length, function(i) {
            return _c(
              "button",
              {
                key: i,
                staticClass: "dot",
                class: { viewed: _vm.currentSlide >= i - 1 },
                on: {
                  click: function($event) {
                    _vm.$refs.carousel.goTo(i - 1)
                  }
                }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v(_vm._s(i))])]
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-background" }, [
      _c("div", { attrs: { id: "bg-center" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-left" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-right" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }