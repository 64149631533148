import Home from "./page/Home.vue";
import Discover from "./page/Discover.vue";
import Setup from "./page/Setup.vue";
import Play from "./page/Play.vue";
import Interlude from "./page/Interlude.vue";
import Credits from "./page/Credits.vue";
import Menu from "./page/Menu.vue";

const routes =
    {
        mode: "hash",
        routes:
            [
                // {
                //     path: "/",
                //     name: "home",
                //     component: Home,
                //     props: false
                // },
    
                {
                    path: "/:lang",
                    name: "menu",
                    component: Menu,
                    props: false
                },
    
                {
                    path: "/:lang/:id/:slug/menu",
                    name: "menu",
                    component: Menu,
                    props: false
                },
    

                
                {
                    path: "/:lang/:id/:slug/discover",
                    name: "discover-en",
                    component: Discover,
                    props: false
                },

                {
                    path: "/:lang/:id/:slug/decouvrir",
                    name: "discover-fr",
                    component: Discover,
                    props: false
                },



                {
                    path: "/:lang/:id/:slug/credits",
                    name: "credits",
                    component: Credits,
                    props: false
                },



                {
                    path: "/:lang/:id/:slug/preparer",
                    name: "setup-fr",
                    component: Setup,
                    props: false
                },

                {
                    path: "/:lang/:id/:slug/setup",
                    name: "setup-en",
                    component: Setup,
                    props: false
                },

                {
                    path: "/:lang/:id/:slug/jouer/:nbTeams/:difficulty/:stopMode",
                    name: "play-fr",
                    component: Play,
                    props: false
                },

                {
                    path: "/:lang/:id/:slug/play/:nbTeams/:difficulty/:stopMode",
                    name: "play-en",
                    component: Play,
                    props: false
                },

                {
                    path: "/:lang/:id/:slug/interlude/:nbTeams/:difficulty/:stopMode/:isComplete?",
                    name: "interlude",
                    component: Interlude,
                    props: false
                }



            ]
    };



export default routes;
