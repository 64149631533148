<template>
	<header class="header">
		<div class="header-title">
			<h1><slot name="title"></slot></h1>
			<slot name="content"></slot>
		</div>
		<div class="header-buttons">
			<slot name="buttons">
				<a v-if="hasExtras" :href="pdfLink" target="_blank"  class="btn-red btn-extras">Extras</a>
				<button v-on:click="showHelp" class="btn-help"><span class="sr-only">Aide</span></button>
				<router-link :to="toHome" class="btn-home" ><span class="sr-only">Accueil</span></router-link>
			</slot>
		</div>
	</header>
</template>

<script>
    import Help from "../page/Help.vue";
    import Hub from '../events/Hub';
    import {mapState} from "vuex";

    export default {
        name: "PageHeader",
        components: {Help},
        data() {
            return {
                settings: false,
                data: {}
            }
        },

        props: ["history"],

        computed:
            {
    
                ...mapState(["faqs"]),
                
                hasExtras ()
                {
                    return this.history.data.pdf_file[this.lang] !== "";
                },
                
                
                toHome ()
                {
                    let id = this.history.id;
                    let historySlug = this.history.data.slug[this.lang];
                    let slug = {fr: "menu", en: "menu"}[this.lang];

                    let path = `/${this.lang}/${id}/${historySlug}/${slug}`;

                    return path;
                },
    
                imagesFolder ()
                {
                    return this.history.data.image_folder;
                },
                
                pdfLink ()
                {
                    return `https://lesaffranchis.s3.amazonaws.com/partitions-codees/${this.imagesFolder}/${this.history.data.pdf_file[this.lang]}`
                },
                
                textHowToPlay ()
                {
                    if (this.history.data.how_to_play)
                    {
                        return [{data: {text: this.history.data.how_to_play }}];
                    }
                    
                    return this.faqs;

                }
            },
        methods:
            {

                showHelp ()
                {
                    Hub.$emit('open-modal');
                    Hub.$emit('set-modal-data', this.textHowToPlay, this.copydeck["TITLE_HOW_TO_PLAY"][this.lang], Help, 'size-large ');
                }
            },

        mounted ()
        {

        }
    }
</script>

<style scoped>

</style>
