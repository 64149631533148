var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", { staticClass: "page play" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-content" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.show
                ? _c("div", [
                    _c("div", { staticClass: "page-foreground" }, [
                      _c("div", { attrs: { id: "fg-center" } }),
                      _vm._v(" "),
                      _c("div", { attrs: { id: "fg-left" } }),
                      _vm._v(" "),
                      _c("div", { attrs: { id: "fg-right" } })
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "page-header",
              { attrs: { history: _vm.history } },
              [
                [
                  _vm._v(
                    "\n\t\t\t\t\tLa Symphonie « Du Nouveau Monde »\n\t\t\t\t"
                  )
                ]
              ],
              2
            ),
            _vm._v(" "),
            _c("settings", { attrs: { disabled: true } }),
            _vm._v(" "),
            _vm.show
              ? _c("partition", {
                  key: "play",
                  staticClass: "is-start",
                  attrs: {
                    mode: "game",
                    id: _vm.id,
                    selector: "gamePreview",
                    height: "700"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-background" }, [
      _c("div", { attrs: { id: "bg-center" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-left" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-right" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }