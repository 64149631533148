<template>

    <div class="page discover">

        <div class="page-background">
            <div id="bg-center"></div>
            <div id="bg-left"></div>
            <div id="bg-right"></div>
        </div>

        <div class="page-content carousel">

            <transition name="fade">
                <div v-if="show">
                    <div class="page-foreground">
                        <div id="fg-center"></div>
                        <div id="fg-left"></div>
                        <div id="fg-right"></div>
                    </div>
                </div>
            </transition>

            <page-header :history="history">
                <template v-slot:title>
                    {{ history.data.title[lang] }}
                </template>
            </page-header>

            <div class="mask">
                <vue-agile ref="carousel" @after-change="setCurrentSlide($event)" class="slides" :dots="false" :infinite="false" :nav-buttons="false">
                    <div class="slide" v-for="(slide, index) in currentSlides" :key="index">

                        <img v-bind:src="slide.img" alt="" width="600" />

                    </div>
                </vue-agile>
            </div>

            <button class="btn-prev" v-on:click="$refs.carousel.goToPrev()" v-bind:disabled="currentSlide === 0">
                <span class="sr-only">Précédent</span>
            </button>

            <button class="btn-next" v-on:click="$refs.carousel.goToNext()" v-bind:disabled="currentSlide === slides.length - 1">
                <span class="sr-only">Suivant</span>
            </button>


            <div class="box" v-html="currentSlides[currentSlide]['caption']">

            </div>

            <div class="dots">
                <button
                        v-for="i in currentSlides.length"
                        :key="i"
                        class="dot"
                        v-bind:class="{ viewed : currentSlide >= i - 1 }"
                        v-on:click="$refs.carousel.goTo(i - 1)"
                >
                    <span class="sr-only">{{ i }}</span>
                </button>
            </div>

        </div>

    </div>
</template>

<script>
    import PageHeader from "../commons/PageHeader.vue";
    import { VueAgile } from 'vue-agile';
    import { mapState } from "vuex";

    export default
    {
        name: "Discover",
        components: {PageHeader, VueAgile},
        data () {
            return {
                show: false,
                currentSlide: 0
            }
        },

        computed:
            {
                 ...mapState(["histories"]),


                history ()
                {
                    let id = this.$route.params.id;
                    let history = this.histories.find ( h => h.id == id);

                    return history;
                },


                slides ()
                {
                    return this.history.pages;
                },

                imagesFolder ()
                {
                    return this.history.data.image_folder;
                },

                currentSlides ()
                {
                    return this.slides.map (
                        slide =>
                            (
                                {
                                    caption: slide.data.content[this.lang],
                                    img: `https://lesaffranchis.s3.amazonaws.com/partitions-codees/${this.imagesFolder}/${slide.data.image[this.lang]}`
                                }))
                }
            },

        methods: {
            setCurrentSlide (event) {
                this.currentSlide = event.currentSlide;
            },

            onMounted ()
            {
                this.show = true;
            }

        },

        mounted()
        {
            setTimeout(() =>
            {
                this.onMounted();
            }, 300);

            // this.$nextTick(function () {
            //     // wait 0.3s then pop things out
            //     setTimeout(() => {
            //         this.show = true;
            //     }, 300);
            // }.bind(this));
        }
    }
</script>
