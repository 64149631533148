var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.disabled
      ? _c("div", [
          _c("div", { staticClass: "settings" }, [
            _c("div", { staticClass: "settings" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.copydeck["LABEL_NUMBER_OF_TEAMS"][_vm.lang]
                  )
                }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "teams-1",
                  name: "nb-teams",
                  value: "1"
                },
                domProps: { checked: _vm.$store.state.nbTeams == 1 },
                on: {
                  click: function($event) {
                    _vm.$store.state.nbTeams = 1
                  }
                }
              }),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "teams-1" } },
                [_vm._v("1")]
              ),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "teams-2",
                  name: "nb-teams",
                  value: "2"
                },
                domProps: { checked: _vm.$store.state.nbTeams == 2 },
                on: {
                  click: function($event) {
                    _vm.$store.state.nbTeams = 2
                  }
                }
              }),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "teams-2" } },
                [_vm._v("2")]
              ),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "teams-3",
                  name: "nb-teams",
                  value: "3"
                },
                domProps: { checked: _vm.$store.state.nbTeams == 3 },
                on: {
                  click: function($event) {
                    _vm.$store.state.nbTeams = 3
                  }
                }
              }),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "teams-3" } },
                [_vm._v("3")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "settings" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.copydeck["LABEL_LEVEL_OF_DIFFICULTY"][_vm.lang]
                  )
                }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "difficulty-easy",
                  name: "difficulty",
                  value: "1"
                },
                domProps: { checked: _vm.$store.state.difficulty == 1 },
                on: {
                  click: function($event) {
                    _vm.$store.state.difficulty = 1
                  }
                }
              }),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "difficulty-easy" } },
                [_vm._v("Facile")]
              ),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "difficulty-medium",
                  name: "difficulty",
                  value: "2"
                },
                domProps: { checked: _vm.$store.state.difficulty == 2 },
                on: {
                  click: function($event) {
                    _vm.$store.state.difficulty = 2
                  }
                }
              }),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "difficulty-medium" } },
                [_vm._v("Moyen")]
              ),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "difficulty-hard",
                  name: "difficulty",
                  value: "3"
                },
                domProps: { checked: _vm.$store.state.difficulty == 3 },
                on: {
                  click: function($event) {
                    _vm.$store.state.difficulty = 3
                  }
                }
              }),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "difficulty-hard" } },
                [_vm._v("Difficile")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "settings" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.copydeck["LABEL_MODE_OF_PLAY"][_vm.lang]
                  )
                }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "mode-uninterrupted",
                  name: "mode",
                  value: "0"
                },
                domProps: { checked: _vm.$store.state.stopMode == 0 },
                on: {
                  click: function($event) {
                    _vm.$store.state.stopMode = 0
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "mode-uninterrupted" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.copydeck["LABEL_MODE_OF_PLAY_CONTINUE"][_vm.lang]
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "mode-interlude",
                  name: "mode",
                  value: "1"
                },
                domProps: { checked: _vm.$store.state.stopMode == 1 },
                on: {
                  click: function($event) {
                    _vm.$store.state.stopMode = 1
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "mode-interlude" } }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.copydeck["LABEL_MODE_OF_PLAY_WITH_PAUSE"][_vm.lang]
                    )
                  }
                })
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.disabled
      ? _c("div", [
          _c("div", { staticClass: "settings" }, [
            _c("div", { staticClass: "settings" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.copydeck["LABEL_NUMBER_OF_TEAMS"][_vm.lang]
                  )
                }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "nb-teams",
                  name: "nb-teams",
                  checked: ""
                },
                domProps: { value: _vm.$store.state.nbTeams }
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "nb-teams" } },
                [_vm._v(_vm._s(_vm.$store.state.nbTeams))]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "settings" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.copydeck["LABEL_LEVEL_OF_DIFFICULTY"][_vm.lang]
                  )
                }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "difficulty",
                  name: "difficulty",
                  checked: ""
                },
                domProps: { value: _vm.$store.state.difficulty }
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "difficulty" } },
                [_vm._v("Facile")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "settings" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.copydeck["LABEL_MODE_OF_PLAY"][_vm.lang]
                  )
                }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "radio",
                  id: "mode-interlude",
                  name: "mode",
                  checked: ""
                },
                domProps: { value: _vm.$store.state.stopMode }
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "mode-interlude" } },
                [_vm._v("Avec pauses")]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }