<template>
	<transition name="quick-fade">
		<div v-if="active">
			<div class="modal-overlay" @click="close"></div>
			<div class="modal" v-bind:class="variant">
				<button class="btn-close" @click="close"><span class="sr-only">Fermer</span></button>
				<h2 class="modal-title">{{ title }}</h2>
				<div class="modal-content scrollable">
					<component :is="component" :data="data"></component>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
    import Hub from '../events/Hub';

    export default {
        name: "Modal",

        data() {
            return {
                active: false,
                title: null,
                variant: null,
                data: {},
				component: null
            }
        },

        components: {},

        destroyed() {
            Hub.$off('set-modal-data', this.set);
            Hub.$off('open-modal', this.open);
        },

        methods:
            {
                close() {
                    this.active = false;
                },
				open() {
                    this.active = true;
                },
                set(data, title, component, variant) {
                    this.data = data;
                    this.title = title;
                    this.variant = variant;
                    this.component = component;
                }
            },
        mounted() {
            this.$nextTick(function () {
                Hub.$on('set-modal-data', this.set);
                Hub.$on('open-modal', this.open);
            }.bind(this));
        }
    }
</script>