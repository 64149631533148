var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page menu" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page-content carousel" },
      [
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.show
            ? _c("div", [
                _c("div", { staticClass: "page-foreground" }, [
                  _c("div", { attrs: { id: "fg-center" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-left" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "fg-right" } })
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "bounce" } }, [
          _vm.show
            ? _c("header", { staticClass: "header" }, [
                _c("h1", { staticClass: "header-logo", class: _vm.logoClass }, [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v("Suivez le rhythme")
                  ])
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "bounce" } }, [
          _c(
            "div",
            { staticClass: "mask" },
            [
              _c(
                "vue-agile",
                {
                  ref: "carousel",
                  staticClass: "slides",
                  attrs: {
                    "initial-slide": _vm.initialSlide,
                    dots: false,
                    infinite: false,
                    "nav-buttons": false
                  },
                  on: {
                    "after-change": function($event) {
                      _vm.setCurrentSlide($event)
                    }
                  }
                },
                _vm._l(_vm.currentHistories, function(slide, index) {
                  return _c("div", { key: index, staticClass: "slide" }, [
                    _c("img", {
                      attrs: { src: slide.img, alt: "", width: "600" }
                    })
                  ])
                }),
                0
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "bounce" } }, [
          _c("h2", {
            staticClass: "slide-title",
            domProps: {
              innerHTML: _vm._s(
                _vm.currentHistories[_vm.currentSlide]["caption"]
              )
            }
          })
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade" } }, [
          _c(
            "button",
            {
              staticClass: "btn-prev",
              attrs: { disabled: _vm.currentSlide === 0 },
              on: {
                click: function($event) {
                  _vm.$refs.carousel.goToPrev()
                }
              }
            },
            [_c("span", { staticClass: "sr-only" }, [_vm._v("Précédent")])]
          )
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "fade" } }, [
          _c(
            "button",
            {
              staticClass: "btn-next",
              attrs: {
                disabled: _vm.currentSlide === _vm.histories.length - 1
              },
              on: {
                click: function($event) {
                  _vm.$refs.carousel.goToNext()
                }
              }
            },
            [_c("span", { staticClass: "sr-only" }, [_vm._v("Suivant")])]
          )
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "bounce" } }, [
          _vm.show
            ? _c(
                "div",
                { staticClass: "box-buttons" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn-purple",
                      attrs: { to: _vm.toDiscover }
                    },
                    [_vm._v(_vm._s(_vm.copydeck["BTN_DISCOVER"][_vm.lang]))]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { staticClass: "btn-red", attrs: { to: _vm.toSetup } },
                    [_vm._v(_vm._s(_vm.copydeck["BTN_PLAY"][_vm.lang]))]
                  )
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "bounce" } }, [
          _c(
            "div",
            { staticClass: "dots" },
            _vm._l(_vm.currentHistories.length, function(i) {
              return _c(
                "button",
                {
                  key: i,
                  staticClass: "dot",
                  class: { viewed: _vm.currentSlide >= i - 1 },
                  on: {
                    click: function($event) {
                      _vm.$refs.carousel.goTo(i - 1)
                    }
                  }
                },
                [_c("span", { staticClass: "sr-only" }, [_vm._v(_vm._s(i))])]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "bounce" } }, [
          _vm.show
            ? _c(
                "p",
                { staticClass: "bottom-buttons" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn-secondary",
                      attrs: { to: _vm.toCredits }
                    },
                    [_vm._v(_vm._s(_vm.copydeck["BTN_CREDITS"][_vm.lang]))]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-background" }, [
      _c("div", { attrs: { id: "bg-center" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-left" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "bg-right" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }