


const UtilsMixins = {

    computed:
        {
            lang ()
            {
                if (this.$store.state.lang)
                    return this.$store.state.lang;

                return "fr";
            },

            copydeck ()
            {
                return this.$store.state.copydeck;
            }
        },


    methods:
        {
            setLang ()
            {


                if (this.$route.params && this.$route.params.lang)
                {

                    let lang = this.$route.params.lang;
                    let arrLang = lang.split("-");
                    let displayLang = arrLang.length <= 1;

                    this.$store.dispatch("lang", arrLang[0]);
                    this.$store.dispatch("displayLang", displayLang);
                }
            }
        }
};

export default UtilsMixins;


