    import axios from "axios";
    import _ from "lodash";
    import Vue from "vue";

    const state =
    {
        lang: "fr",
        langs: ["fr", "en"],
        displayLang: null,
        nbTeams: 3,
        difficulty: 1,
        stopMode: 0,
        sections: 0,
        shouldShowHelp: true,

        copydeck: {},
        faqs:[],
        histories:[],
        history: {},
        theme: 'default',
        slides: [],
        instruments: [],
        credits:[]
    };

    const getters =
        {

        };


    const actions =
        {

            async all ({commit, getters})
            {
                let context = document.querySelector("body").getAttribute("data-context");
                let response = await axios.get(`/api/data/all/${context}`);

                commit("all", response.data);
            },

            async slides ({commit})
            {
                let response = await axios.get("/api/history/load/1");

                commit("slides", response.data);
            },

            settings ({commit}, payload)
            {
                commit("settings", payload);
            },

            toggleLang ({commit})
            {
                commit("toggleLang");
            },

            lang ({commit}, payload)
            {
                commit("lang", payload)
            },

            displayLang ({commit}, payload)
            {
                commit("displayLang", payload)
            },

            async init ({commit})
            {
                let shouldShowHelp = true;

                try
                {
                    let strShouldShowHelp = localStorage.getItem("slr.shouldShowHelp");

                    shouldShowHelp = strShouldShowHelp !== "false";
                }

                catch (e)
                {
                    shouldShowHelp = "true";
                }

                commit("shouldShowHelp", Boolean(shouldShowHelp));

            }





        };

    const mutations =
        {

            all (state, payload)
            {
                Vue.set(state, "slides", payload.histories[0].pages);
                Vue.set(state, "history", payload.histories[0]);
                Vue.set(state, "histories", payload.histories);
                Vue.set(state, "instruments", payload.instruments);
                Vue.set(state, "copydeck", payload.copydeck);
                Vue.set(state, "faqs", payload.faqs);
                Vue.set(state, "sections", payload.sections);
                Vue.set(state, "credits", payload.credits);
            },


            slides (state, payload)
            {
                Vue.set(state, "slides", payload);
            },

            settings (state, payload)
            {
                Vue.set(state, "nbTeams", payload.nbTeams);
                Vue.set(state, "difficulty", payload.difficulty);
                Vue.set(state, "stopMode", payload.stopMode == 0 ? 0 : 1);
            },


            toggleLang (state)
            {
                let lang = state.lang;

                Vue.set(state, "lang", lang === "fr" ? "en" : "fr");
            },

            lang (state, lang)
            {
                if (!lang || lang === '')
                    lang = "fr";

                Vue.set(state, "lang", lang);
            },

            displayLang (state, displayLang)
            {
                if (state.displayLang === null)
                    Vue.set(state, "displayLang", displayLang);
            },

            theme(state, payload)
            {
              Vue.set(state, "theme", payload);
            },

            currentHistory (state, index)
            {
                state.history = state.histories[index];
                state.slides = state.history.pages;
            },


            shouldShowHelp (state, shouldShowHelp)
            {
                Vue.set(state, "shouldShowHelp", shouldShowHelp);

                try
                {
                    localStorage.setItem("slr.shouldShowHelp", shouldShowHelp);
                }

                catch (e)
                {

                }
            }
        };


    let store =
        {
            state,
            getters,
            mutations,
            actions
        }
    ;

    export default store;


